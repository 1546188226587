//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EntityThumbnail from '@/components/EntityThumbnail.vue'
export default {
    props: ['data'],
    components: { EntityThumbnail },
}
