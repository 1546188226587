//
//
//
//
//
//
//
//
//
//
//
//

import defaultImg from '@/assets/default_store.jpg'
export default {
name: 'EntityThumbnail',
    props: [
      'entity_id',
      'width',
      'entity_type_id'
    ],
    data(){
        return{
            isLoading: true,
            image: defaultImg,
            entity: null,
            entity_type: null
        }
    },
    methods:{
        onImgError() {
            this.$q.notify({
              message:"Could not load image. Falling back to default photo.",
              color: 'negative',
              icon: 'error'
            })
      },
      loadImage() {
        if(this.entity_id){
            let payload = {id: this.entity_id}
            switch(this.entity_type_id){
                case '1': 
                    //Group
                    this.entity_type = 'group'
                    this.entity = 'Group'
                    break;
                case '2':
                    //Event
                    this.entity_type = 'event'
                    this.entity = 'Event'
                    break;
            }
          this.$store.dispatch(this.entity_type+'/getCoverPhoto', payload)
          .then((response) =>{
            if(response.status == 'success'){
              this.image = response.image
              this.isLoading = false;
            }
          })
        }
      },   
    },
    created(){
        this.loadImage()
    }
}
